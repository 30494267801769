import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);
Vue.config.productionTip = false
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load:{
    key:'AIzaSyCJnct2Fg7CzmMjySbLnCAmhWJ8mH2JTAU'
  },
 
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
