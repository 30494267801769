<template>
  
  <nav  class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#"></a>
    
    <form
              class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search" style="margin: 10px" >
            <div class="input-group" style="margin: 5px" >
              <input type="text" class="form-control bg-light border-0 small" placeholder="Enter an address, city or ZIP code"
                     aria-label="Search" aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-primary" style="margin: 5px" type="button">
                  <i class="fas fa-search fa-sm"></i>
                </button>
              </div>
            </div>
          </form>
          
          
Filtres
<li class="btn btn-primary" style="margin: 15px">
        <router-link to="/sale" class="nav-link">
          <i class=""></i>
          <span>On Sale</span></router-link>
      </li>
      <li class="btn btn-primary" style="margin: 15px">
        <router-link to="/rent" class="nav-link">
          <i class=""></i>
          <span>On Rent</span></router-link>
      </li>

      <li class="btn btn-primary" style="margin: 15px">
                <router-link to="/edificio" class="nav-link">
          <i class=""></i>
          <span>Estructures</span></router-link>
      </li>
      <li class="btn btn-primary" style="margin: 15px">
        <router-link to="/precio" class="nav-link">
          <i class=""></i>
          <span>Prices</span></router-link>
      </li>



<!-- dropdown fallido -->
      <!-- <li class ="dropdown background">
            <a class="dropdown-toggle width" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Precio Max</a>
            <div class="dropdown-menu dropdown-menu-right dropdown-cyan">
              <input class="dropdown-item" style="..." placeholder="ingresa valor max" @change="filtrarCasa('maxPrice')"></input>
            </div>
          </li> -->

          <!-- <Dropdown


          
    :options="[{ id: 1, name: 'Option 1'}, { id: 2, name: 'Option 2'}]"
    v-on:selected="validateSelection"
    v-on:filter="getDropdownValues"
    :disabled="false"
    name="zipcode"
    :maxItem="10"
    placeholder="Please select an option">
</Dropdown>
          
          <div class="dropdown-ForSale">
  <button class="btn btn-primary dropdown-toggle" style="margin: 15px" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    For Sale 
  </button>
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">sale 1</a></li>
    <li><a class="dropdown-item" href="#">sale 2</a></li>
    <li><a class="dropdown-item" href="#">sale 3</a></li>
  </ul>
</div>


<div class="dropdown-tipo">
  <button class="btn btn-primary dropdown-toggle" style="margin: 15px" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Type House
  </button>
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">tipo 1</a></li>
    <li><a class="dropdown-item" href="#">tipo 2</a></li>
    <li><a class="dropdown-item" href="#">tipo 3</a></li>
  </ul>
</div>


          <div class="dropdown">
  <button class="btn btn-primary dropdown-toggle" style="margin: 15px" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Min Price
  </button>
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">"-1,000,000.00"</a></li>
    <li><a class="dropdown-item" href="#">1,000,000.00</a></li>
    <li><a class="dropdown-item" href="#">+1,000,000.00</a></li>
  </ul>
</div>

<div class="dropdown">
  <button class="btn btn-primary dropdown-toggle" style="margin: 15px" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Max Price
  </button>
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">"-1,000,000.00"</a></li>
    <li><a class="dropdown-item" href="#">1,000,000.00</a></li>
    <li><a class="dropdown-item" href="#">+1,000,000.00</a></li>
  </ul>
</div>

<div class="dropdown">
  <button class="btn btn-primary dropdown-toggle" style="margin: 15px" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Floor Area 
  </button>
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">20 m²</a></li>
    <li><a class="dropdown-item" href="#">40 m²</a></li>
    <li><a class="dropdown-item" href="#">60 m²</a></li>
  </ul>
</div> -->


    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul v-for="filter in filters" :key="filter" class="navbar-nav">
        <li v-for="data in filter" :key="data" class="nav-item active">{{filter}}
        </li>
      </ul>
    </div>
  </nav>
  
</template>

</script>
<script>

export default {
  name: "NavBar",
  props:{
    objects:{
      type:[Object]
    },
    filters:{
      type:[String]
    }
  },
  data(){
    return{
      buildingType:this.objects.buildingType
    }
  }
}
</script>

<style scoped>


.dropdwon:hover .dropdwon-menu{
  display: block;
}
.hm-gradient{
  background-color:brown
}
.navbar .dropdwon-menu a:hover {
  color: rgb(212, 125, 11) !important;
}

</style>
