<template>
    <div>
      <h1>Venta Page</h1>
      <div class="row">
        <div class="col-sm">
          <HousesCards
              :objects="housesArray"
          ></HousesCards>
        </div>
        <div class="col-sm">
          <ul v-for="houses in housesArray" :key="houses" class="list-group list-group-flush">
            <b class="list-group-item">Casa: {{houses.title}}</b>
            <li class="list-group-item">Esta en: {{houses.houseType}}</li>         
            <li class="list-group-item">Contacto: {{houses.ownerPhone}}</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import HousesCards from "@/components/HousesCards";
  
  export default {
    name: "saleView",
    components: {
      HousesCards,
    },
    data: function() {
      return{
        housesArray: [],
        filters:[
         
          "sale"
        ]
      }
    },
    methods:{
      async getFavouriteHouses(){
        const result = await axios.get("https://silly-hospital-gown-seal.cyclic.app/house/get-fav");
        this.housesArray = result.data.obj
        console.log(result.data.obj)
      }
    },
    beforeMount() {
      this.getFavouriteHouses()
    }
  }
  </script>
  
  <style scoped>
  
  </style>