<template>
  <div>
    <NavBar
    :objects="housesArray"></NavBar>

    <h1>Home</h1>
    <HousesCards
        :objects="housesArray"
        :filters="filters"></HousesCards>

    <HousesCards
        :objects="housesArray"
        :filters="filters"></HousesCards>
    <button type="button" class="btn btn-primary" @click="$router.push('/favourites')">Favoritos</button>
  </div>
</template>

<script>


// @ is an alias to /src
import HousesCards from '@/components/HousesCards.vue';
import axios from 'axios';
import NavBar from "@/components/NavBar";

export default {
  name: 'HomeView',
  data: function() {
    return{
      housesArray: [],
      filters:[
          "Tipo",
          "Edificio",
          "Precio"
      ]
    }
  },
  components: {
    HousesCards,
    NavBar
  },
  methods:{
    async getAllHouses(){
      const result = await axios.get("https://silly-hospital-gown-seal.cyclic.app/house/list");
      this.housesArray = result.data.obj
      console.log(result.data.obj)
    }
  },
  
  beforeMount(){
    this.getAllHouses();
  }
};
</script>
