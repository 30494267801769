<template>
  <div class="container">
    
    <div v-for="object in objects" :key="object._id" class="card">
      <div class="box">
        <div class="top">
            <img :src="object.image"/>
        </div>
        <div class="bottom" variant="outline-light">
          <div class="card-title">
            <h3>{{object.title}}</h3>
          </div>
          <div class="card-body">
            <p>
              {{object.address}}
            </p>
            <div class="price">
             <p> <span>{{object.houseType}}</span></p>
              <span>$ {{object.price}}</span>
              <button  class="btn text-primary " v-on:click="addFavourite(object)" ><i v-bind:class="[object.favourite ?  'fa-heart' : 'fa-heart-o', 'fa']" aria-hidden="true"></i></button>
              <div class="row">
                <button 
                    type="button"
                    class="btn btn-outline-primary" 
                    @click="showModal"
                >
                  Mas informacion
                </button>
              </div>
              


              <HouseInfoModal
                  v-show="isModalVisible"
                  @close="closeModal"
                  :objects="object"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

</template>

<script>
import axios from "axios";
import HouseInfoModal from "@/components/HouseInfoModal";

export default {
  name: 'HousesCards',
  props:{
    objects:{
      type:[Object]
    }
  },
  components:{
    HouseInfoModal
  },
  data(){
    return{
      houses:this.objects,
      isModalVisible:false
    }
  },
  methods:{
    async addFavourite(casa){
      if (casa.favourite == false) {
        await axios.post('https://silly-hospital-gown-seal.cyclic.app/house/add-fav', {
          houseId: casa._id,
        })
      } else {
        await axios.post('https://silly-hospital-gown-seal.cyclic.app/house/delete-fav',{
          houseId:casa._id,
        })
      }
      casa.favourite = !casa.favourite;
    },
    showModal() {
      this.isModalVisible = true;
      console.log("click")
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },

};
</script>

<style scoped>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #eee;
}

.container {
  width: 1100px;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
}
.container .box .top {
  position: relative;
  background-color: red;
}

.container .box .top:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(43, 187, 175, 0.829);
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.3s;
  opacity: 0;
}

/*.container .box .top:hover:before {*/
/*  opacity: 1;*/
/*}*/

.container .box .top:after {
  color: white;
  font-size: 12px;
  padding: 10px;
  border: 1px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: 0.3s;
  opacity: 0;
}

.container .box .top:hover:after {
  opacity: 1;
}

.container .box .top img {
  width: 100%;
  margin-bottom: -4px;
}

.container .box .top span {
  position: absolute;
  right: 20px;
  bottom: 15px;
  color: white;
  font-size: 20px;
}

.container .box .top span i {
  margin-left: 5px;
}

.container .box .bottom {
  padding: 20px 15px;
  position: relative;
}

.container .box .bottom h3 {
  font-size: 15px;
}

.container .box .bottom > p {
  margin: 15px 0;
  font-size: 13px;
  line-height: 1.4;
  color: #777;
}

.container .box .bottom .advants {
  display: flex;
}

.container .box .bottom .advants > div {
  margin-right: 15px;
}

.container .box .bottom .advants > div > span {
  font-weight: bold;
  font-size: 12px;
}

.container .box .bottom .advants > div > div {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.container .box .bottom .advants > div > div i {
  color: #777;
  font-size: 22px;
}

.container .box .bottom .advants > div > div > span {
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

.container .box .bottom .advants > div > div > span > span {
  color: #777;
  font-weight: normal;
  margin-left: 5px;
}

.container .box .bottom .price {
  margin-top: 20px;
}

.container .box .bottom .price span:first-of-type {
  display: block;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

.container .box .bottom .price span:last-of-type {
  color: #3eaba1;
  font-size: 22px;
}


</style>
