<template>
  
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
         {{objects.buildingType}} ON {{objects.houseType}}
        </slot>
        <button
            type="button"
            class="btn-close"
            @click="close"
        >
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          {{objects.title}}
        </slot>
        <slot name="body">
          <div class="col">
            <img :src="objects.image"/>
          </div>

        </slot>
      </section>

      <footer class="modal-footer">
        <slot name="footer">
          <div class="col">
              <h3>Descripcion </h3>
              <p>
                {{objects.description}}
              </p>

          </div>
          <div class="col">
              <h3>Informacion</h3>
              <div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">Propietario: {{objects.ownerName}}</li>
                  <li class="list-goup-item"> <h3>Precio</h3> </li>
                  <li class="list-group-item"><h4>$300,000,000</h4></li>
                </ul>
              </div>
              <div>
                <MapsComponent>

                </MapsComponent>
             

              </div>
          </div>
        </slot>
        <ul class="list-group">
          <h4>Contacto</h4>
          <div class="direccion"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
          </svg> Calle Toscana #9300.
            Plaza Calabria, local 14, Pedregal de San Ángel.</div>
          <div class="numero"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
          </svg>{{objects.ownerPhone}}</div>
          <div class="direccion"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
          </svg>Lun-Vie: 10 am - 7 pm
            Sábados: 11 am - 6 pm
            Domingos: 11 am - 2 pm</div>

        </ul>

        <button 
            type="button"
            class="btn btn-primary btn-lg"
            @click="close"
        >
          Close 
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import MapsComponent from '@/components/MapsComponent';
export default {
  name: "HouseInfo",
  components: {
    MapsComponent
  },
  props:{
    objects:{
      type:Object
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}
</style>


