import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginView from '../views/LoginView.vue';
import HomeView from '../views/HomeView.vue';
// import About from '../views/About';
import RegisterView from '@/views/RegisterView';
import PortalVue from 'portal-vue'
import FavouritesView from "@/views/FavouritesView";
import edificio from "@/views/edificio";
import precio from "@/views/precio";
import sale from "@/views/sale";
import rent from "@/views/rent";

// import Dropdown from 'vue-simple-search-dropdown';
// import DropdownMenu from '@innologica/vue-dropdown-menu'


Vue.use(PortalVue)
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    children: []
  },

  // {
  //   path: '/About',
  //   name: 'About',
  //   component: About
  // },
  // {
  //   path: '/Help',
  //   name: 'Help',
  //   component: HelpView,
  //   children: []
  // },
  // {
  //   path: '/Real',
  //   name: 'Real',
  //   component: RealView,
  //   children: []
  // },
  // {
  //   path: '/Blog',
  //   name: 'Blog',
  //   component: BlogView,
  //   children: []
  // },
  {
    path: '/newuser',
    name: 'newuser',
    component: RegisterView
  },
  {
    path:'/favourites',
    name:'favourites',
    component: FavouritesView
   },
  {
    path:'/edificio',
    name:'edificio',
    component: edificio
  },
  {
    path:'/precio',
    name:'precio',
    component: precio
  },
  {
    path:'/sale',
    name:'sale',
    component: sale
  },
  {
    path:'/rent',
    name:'rent',
    component: rent
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
