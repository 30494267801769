<template>
  <div>
    <h1>Favourites Page</h1>
    <div class="row">
      <div class="col-sm">
        <HousesCards
            :objects="housesArray"
        ></HousesCards>
      </div>
      <div class="col-sm">
        <ul v-for="houses in housesArray" :key="houses" class="list-group list-group-flush">
          <li class="list-group-item">Casa: {{houses.title}}</li>
          <li class="list-group-item">Propietario: {{houses.ownerName}}</li>
          <li class="list-group-item">Contacto: {{houses.ownerPhone}}</li>
          <li class="list-group-item"> *** </li>
          <li> <maps-component></maps-component> </li>
          
        </ul>
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HousesCards from "@/components/HousesCards";
import MapsComponent from '@/components/MapsComponent'

export default {
  name: "FavouritesView",
  components: {
    HousesCards, MapsComponent
  },
  data: function() {
    return{
      housesArray: [],
      filters:[
        "Tipo",
        "Edificio",
        "Precio"
      ]
    }
  },
  methods:{
    async getFavouriteHouses(){
      const result = await axios.get("https://silly-hospital-gown-seal.cyclic.app/house/get-fav");
      this.housesArray = result.data.obj
      console.log(result.data.obj)
    }
  },
  beforeMount() {
    this.getFavouriteHouses()
  }
}
</script>

<style scoped>

</style>
