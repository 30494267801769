<template>
    <div>
     
      <GmapMap
        :center="center"
        :zoom="10"
        style="width:100%;  height: 555px;">
        <GmapMarker
          :key="index"
          v-for="(gmp, index) in locations"
          :position="gmp"
          @click="center=gmp"
        ></GmapMarker>

        <GMapInfoWindow >
            <div>I am in info window <MyComponent/>
                
            </div>
          </GMapInfoWindow>
      </GmapMap>
   
    </div>
  </template>
   
  <script>
  export default {
    name: "DrawGoogleMap",
    data() {
      return {
        center: { 
            lat: 39.7837304, 
            lng: -100.4458825 
        },
        locations: [],
        currentLocation: null
      };
    },
   
    mounted() {
      this.setLocationLatLng();
    },
   
    methods: {
      setPlace(loc) {
        this.currentLocation = loc;
      },
      setLocationLatLng: function() {
          navigator.geolocation.getCurrentPosition(geolocation => {
            this.center = {
              lat: geolocation.coords.latitude,
              lng: geolocation.coords.longitude
            };
          });
   
          this.locations = [
            {
                lat: 28.6959578,
                lng: -106.1992914,
                label: 'Casa1'
            },
            {
                lat: 29.6929545,
                lng: -106.1992166,
                label: 'TownHouseCasa'
            }
           
        ];
   
      }
    }
  };
  </script>